
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CandidateTileAvailableCommands from "@/components/ats/Candidate/CandidateTileAvailableCommands.vue";
import ActionButton from '@/components/ActionButton.vue';
import NewIcon from '@/components/NewIcon.vue';


@Component({
    name: "CandidateLog",
    components: {CandidateTileAvailableCommands, ActionButton, NewIcon}
})
export default class CandidateFollowingLog extends Vue {
    isReadOnly = true;
    @Prop() log: any;

    editLog() {
        this.$emit('saveLogUpdate', this.log.textArea);
        this.toggleEditMode()
    }

    toggleEditMode(mode = '') {
        if(mode === 'textarea' && this.isReadOnly === false) return;

        this.isReadOnly = !this.isReadOnly;
    }
}
