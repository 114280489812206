
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CandidateLog, ICandidateDB, IWorkerLogsContent, IFilter, IInterviewDB, IItemFilter, ILogDB, IMessageTemplateDB, IUserDB, StoreAction} from "@/types";
import moment from "moment-timezone";
import {CandidateCommand, CandidateLogType, MessageTemplateType, ROUTES} from "@/data";
import {Action, Getter, State} from "vuex-class";
import {capitalize, getFormattedHour, isValidEmail, toastError} from "@/helpers/commons";
import CandidateTileAvailableCommands from "@/components/ats/Candidate/CandidateTileAvailableCommands.vue";
import ActionButton from '@/components/ActionButton.vue';
import { mixins } from 'vue-class-component';
import CandidateMixin from '@/mixins/CandidateMixin.vue';
import NewIcon from '@/components/NewIcon.vue';
import CandidateFollowingLog from '@/components/ats/Candidate/CandidateFollowingLog.vue';
import Tooltip from '@/components/Tooltip.vue';
import { ESortByDate, ELogType } from "@/data";
import Dropdown from '@/components/Dropdown.vue';

/*

TODO: génériser les commandes afin de ne pas répéter la logique
Il faut extraire les boutons et réussir  a faire varier l e comportement
de chaque commande en fonction d'un  contexte.

*/

@Component({
    name: "Following",
    components: {CandidateTileAvailableCommands, ActionButton, NewIcon, CandidateFollowingLog, Tooltip, Dropdown}
})
export default class Following extends mixins(CandidateMixin) {

    @Action("actions/getMessagesTemplates") getMessagesTemplates!: StoreAction;
    @Action("actions/sendCommandCandidate") sendCommandCandidate!: StoreAction;
    @Action("actions/loadLogs") loadLogs!: StoreAction;

    @Getter('getCollaborator') getCollaborator!: any;
    @Getter('isSupervisor') isSupervisor!: boolean;

    @State('currentUser') currentUser!: IUserDB;

    templates: Array<IMessageTemplateDB> = [];
    message: String = "";
    CandidateLogType = CandidateLogType;
    loading = false;
    globalErrors = [];
    note = "";
    commandsOpen: boolean = false
    sort: 'asc' | 'dsc' = 'asc'
    logs: any[] = [];
    formatHour = getFormattedHour;

    groupedLogs(logs: ILogDB<IWorkerLogsContent>[]) {
        logs = (logs.filter((l: ILogDB<IWorkerLogsContent>, index: number) => {
            return l.type === ELogType.CHANGE_STATUS
                || l.type === ELogType.CONTACT
                || l.type === ELogType.CREATE
                || l.type === ELogType.FEEDBACK
                || l.type === ELogType.INTEGRATE
                || l.type === ELogType.INTERVIEW
                || l.type === ELogType.NOTE
        })
        .map((l: ILogDB<IWorkerLogsContent>) => {
            const obj:any = {...l};
            obj.hour = moment(l.timestamp).format('HH:mm');

            let c = this.getCollaborator(l.collaborator._id);

            if (c) {
                obj.collaborator = `${capitalize(c.firstname)} ${capitalize(c.lastname)}`;
            } else if (l.collaborator._id === this.currentUser._id) {
                obj.collaborator = `${capitalize(this.currentUser.firstname)} ${capitalize(this.currentUser.lastname)}`;
            }
            if (l.type === ELogType.CONTACT) {
                obj.textContent = this.$t('page.candidate.logs.' + l.content.contactType + '.text', { collaborator: obj.collaborator, candidate: this.candidateName});
            } else {
                obj.textContent = this.$t('page.candidate.logs.' + l.type + '.text', { collaborator: obj.collaborator, candidate: this.candidateName});
            }

            obj.editMode = false;

            switch(l.type) {
                case ELogType.NOTE:
                case ELogType.FEEDBACK:
                    obj.textArea = l.message;
                    obj.editMode = true;
                    break;
                case ELogType.INTERVIEW:
                    const interview = this.candidate.interviews?.find((interview: IInterviewDB) => {
                        return interview._id === l.content.interviewId;
                    });
                    if(!interview) break;
                    obj.text = this.$t('page.candidate.logs.' + l.type + '.subtitle',
                    {
                        date: moment(interview.date).format(this.$t('date.format')),
                        startHour: this.formatHour(interview.start),
                        endHour: this.formatHour(interview.end),
                        collaborator: obj.collaborator,
                    });
                    break;
            }
            if (l.content.contactType === 'phone-report') {
                obj.editMode = true
            }
            return obj;
        }));

        let groupedLogs: any = {};
        logs.forEach((l: ILogDB<IWorkerLogsContent>) => {
            const date = moment(l.timestamp).format('DD MMMM YYYY');

            if(!groupedLogs[date]) {
                groupedLogs[date] = [{...l}];
            }
            else {
                groupedLogs[date].push(l);
            }
        })

        return Object.entries(groupedLogs);
    }

    get templateOptions() {
        return [
            {label: "", value: ""},
            ...this.templates.map(template => {
                return {label: template.name, value: template.text};
            })
        ];
    }

    get candidateName() {
        return `${capitalize(this.candidate.firstname) || ''} ${capitalize(this.candidate.lastname) || ''}`;
    }

    get commands() {
        return (this.candidate.availableCommands || []).filter((c: string) => {
            return c === "send-message"
            // && c !== "update";
        });
    }

    interviewMessage() {
        return this.candidate.interviews[this.candidate.interviews.length-1]?.collaboratorMessage || '';
    }

    candidateChanged(data: any) {
        if (data.command === CandidateCommand.integrate && data.req.placed) {
            return this.$router.push({name: ROUTES.APP.MISSIONCREATION, query: { missionId: data.result.jobOffer.missionId }})
        }
        if (data.command === CandidateCommand.integrate) {
            return this.$router.push({name: ROUTES.APP.INTERIM, params: { interimId: data.result.workerId }})
        }

        this.$emit('updateCandidate', data.result);
    }

    async sendMessage() {
        this.loading = true;
        try {
            if (isValidEmail(this.candidate.email || '') === false) {
                this.$toast.open({
                    message: this.$t("errors.Message.Email.empty").toString(),
                    type: "error",
                    duration: 10000
                });
                this.loading = false;
                return;
            }
            const result = await this.sendCommandCandidate({
                candidateId: this.candidate._id,
                command: CandidateCommand.sendMessage,
                data: {
                    collaboratorId: this.currentUser._id,
                    message: this.message
                }
            });
            this.message = '';
            this.$emit('updateCandidate', result);
        } catch (e) {
            console.log("--------", e);
            this.globalErrors = e._global;
            this.loading = false;
            // @ts-ignore;
            if (this.message === '') {
                this.$toast.open({
                    message: this.$t("errors.Message.Required.empty").toString(),
                    type: "error",
                    duration: 10000
                });
            }
            throw e;
        }
        this.loading = false;
    }

    async addCandidateNote() {
        try {
            this.loading = true;
            this.candidate.candidateLogs?.push({
                type: CandidateLogType.note,
                timestamp: new Date().valueOf(),
                collaboratorId: this.currentUser._id,
                message: this.note
            });
            await this.updateCandidate();
            this.note = "";
            this.loading = false;
        } catch(e) {
            this.loading = false;
            console.log(e)
        }
    }

    async updateCandidateLog(log: string, index: number) {
        this.candidate.candidateLogs[index].message = log;

        await this.updateCandidate();
    }

    async init() {
        try {
            this.loading = true;
            this.logs = await this.loadLogs({
                relatedTo: this.candidate._id,
                filteringAgencies: [],
                sort: this.sort,
                databaseOrigin: 'candidates'
            });
            this.logs = this.groupedLogs(this.logs);
            this.loading = false;
            this.templates = await this.getMessagesTemplates({type: MessageTemplateType.ats_agency_message}) || [];
        } catch (e) {
            console.log(e);
        }
    }

    beforeMount() {
        this.$emit('showHideAddCandidateButton', true)
        this.init();
    }
    //  A ACTIVER QUAND ON METTRA EN PLACE LE PARTAGE DES INFORMATIONS ENTRE AGENCES
    // filterByAgency(agency: IItemFilter) {
    //     const agencyIndex = this.filter[0].items.findIndex((item: IItemFilter) => item.value === agency.value);

    //     this.filter[0].items[agencyIndex].selected = !this.filter[0].items[agencyIndex].selected;

    //     const agencies: String[] = [];
    //     for(const section of this.filter) {
    //         for(const item of section.items) {
    //             if (item.selected) {
    //                 agencies.push(item.value);
    //             }
    //         }
    //     }

    //     if (agencies.length === 0 ) {
    //         this.placeholder = "0 agence"
    //     }

    //     if (agencies.length === 1 ) {
    //         this.placeholder = "1 agence"
    //     }

    //     if (agencies.length > 1 && agencies.length < this.filter[0].items.length) {
    //         this.placeholder = `${agencies.length} agences`;
    //     }

    //     if (agencies.length === this.filter[0].items.length) {
    //         this.placeholder = "Toutes les agences"
    //     }

    //     this.filterLogs(this.sort, agencies)
    // }

    filterByDate() {
        // A DECOMMENTER QUAND ON METTRA EN PLACE LE PARTAGE DES INFORMATIONS ENTRE LES AGENCES
        // const agencies: String[] = [];
        // for(const section of this.filter) {
        //     for(const item of section.items) {
        //         if (item.selected) {
        //             agencies.push(item.value);
        //         }
        //     }
        // }

        switch (this.sort) {
            case 'asc':
                this.sort = 'dsc';
                this.filterLogs(this.sort, []);
                break;

            case 'dsc':
                this.sort = 'asc';
                this.filterLogs(this.sort, []);
                break;
        }
    }

    async filterLogs(sort: String, agencies: String[]) {
        this.logs = await this.loadLogs({
                relatedTo: this.candidate._id,
                filteringAgencies: agencies,
                sort: sort,
                databaseOrigin: 'candidates'
            });
        this.logs = this.groupedLogs(this.logs);
    }
}
